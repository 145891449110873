export const authMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: '/login',
		hide: true,
	},
	confirmLogin: {
		id: 'confirmLogin',
		text: 'Please Verify Your account',
		path: '/login/secured/2fa',
		hide: true,
	},
	joinTeam: {
		id: 'accept_invitation',
		text: 'Join MeVerify Team',
		path: '/accept_invitation',
		hide: true,
	},
	profile: {
		id: 'profile',
		text: 'My Profile',
		path: '/profile',
		hide: true,
	},
	forgetGroup: {
		id: 'forget',
		text: 'Reset Password',
		hide: true,
		subMenu: {
			forgetPassword: {
				id: 'forgetPassword',
				text: 'Get Help',
				path: '/forget-password',
				hide: true,
			},
			confirmation: {
				id: 'confirmation',
				text: 'Verify your account',
				path: '/reset-verification',
				hide: true,
			},
			resetPassword: {
				id: 'reset',
				text: 'Reset your password now',
				path: '/reset-password',
				hide: true,
			},
		},
	},
};

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		permission: 'view dashboard',
		subMenu: null,
	},
	systemApiKeys: {
		id: 'systemApiKeys',
		text: 'System Api Keys',
		path: '/system-api-keys',
		icon: 'VpnKey',
		permission: 'view system api keys',
	},
	currencies: {
		id: 'currencies',
		text: 'Currencies',
		path: '/currencies',
		icon: 'AttachMoney',
		permission: 'view currencies',
	},
	users: {
		id: 'users',
		text: 'Users',
		path: '/users',
		icon: 'Person',
		permission: 'view users',
		subMenu: null,
	},
	transactions: {
		id: 'transactions',
		text: 'Transactions',
		path: '/transactions',
		icon: 'ShowChart',
		permission: 'view transactions',
		subMenu: null,
	},
};

export const institutions = {
	institutions: {
		id: 'institutions',
		text: 'Institutions',
		permission: 'view institutions',
		icon: 'Extension',
	},
	companies: {
		id: 'companies',
		text: 'Company',
		path: '/institutions/companies',
		permission: 'view companies',
		icon: 'GridView',
	},
	projects: {
		id: 'projects',
		text: 'Projects',
		path: '/institutions/projects',
		permission: 'view projects',
		icon: 'Terrain',
	},
	apiCall: {
		id: 'apiCalls',
		text: 'API Call',
		path: '/institutions/api-calls',
		permission: 'view api calls',
		icon: 'BackHand',
	},
	profiles: {
		id: 'profiles',
		text: 'Profiles',
		path: '/institutions/profiles',
		icon: 'AutoAwesomeMotion',
		permission: 'view profiles',
	},
	wallets: {
		id: 'wallets',
		text: 'Wallets',
		path: '/institutions/wallets',
		icon: 'BubbleChart',
		permission: 'view wallet',
	},
};

export const productsMenu = {
	institutions: {
		id: 'products',
		text: 'Products',
		permission: 'view products',
		icon: 'Extension',
	},
	plans: {
		id: 'plans',
		text: 'Plans',
		path: '/institutions/plans',
		icon: 'AutoAwesomeMotion',
		permission: 'view plans',
	},
	products: {
		id: 'products',
		text: 'Products',
		path: '/institutions/products',
		icon: 'ProductionQuantityLimits',
		permission: 'view products',
	},
};

export const administrations = {
	administrations: {
		id: 'administrations',
		text: 'Administrations',
		icon: 'Extension',
		permission: 'view administrations',
	},
	roles: {
		id: 'roles',
		text: 'Roles',
		path: '/administrations/roles',
		icon: 'Login',
		permission: 'view roles',
	},
	staff: {
		id: 'staff',
		text: 'Staff',
		path: '/administrations/staff',
		icon: 'PersonAdd',
		permission: 'view administrators',
	},
	notifications: {
		id: 'notifications',
		text: 'Notifications',
		path: '/account-settings',
		icon: 'NotificationAdd',
		permission: null,
		hide: true,
	},
	profile: {
		id: 'Profile',
		text: 'Profile',
		path: '/profile',
		icon: 'Person',
		hide: true,
		permission: null,
	},
};
