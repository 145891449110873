import React, { lazy } from 'react';
import { dashboardMenu, authMenu, institutions, productsMenu, administrations } from '../menu';

const AUTH = {
	LOGIN: lazy(() => import('../pages/auth/AuthPage')),
	CONFIRM_LOGIN: lazy(() => import('../pages/auth/ConfirmLogin')),
	FORGET_PASSWORD: lazy(() => import('../pages/auth/ForgetPage')),
	CONFIRM_RESET: lazy(() => import('../pages/auth/ConfirmReset')),
	RESET_PASSWORD: lazy(() => import('../pages/auth/ResetPassword')),
	JOIN_TEAM: lazy(() => import('../pages/auth/JoinTeam')),
};

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	SYSTEM_API_KEYS: lazy(() => import('../pages/dashboard/apikey/SystemApiKey')),
	EDIT_SYSTEM_API_KEYS: lazy(() => import('../pages/dashboard/apikey/EditSystemApiKey')),
	USERS: lazy(() => import('../pages/dashboard/user/User')),
	SINGLE_USER: lazy(() => import('../pages/dashboard/user/SingleUser')),
	TRANSACTIONS: lazy(() => import('../pages/dashboard/transaction/Transaction')),
	SINGLE_TRANSACTION: lazy(() => import('../pages/dashboard/transaction/SingleTransaction')),
	CURRENCY: {
		HOME: lazy(() => import('../pages/dashboard/currencies/Currency')),
		SINGLE: lazy(() => import('../pages/dashboard/currencies/SingleCurrency')),
		EDIT: lazy(() => import('../pages/dashboard/currencies/EditCurrency')),
	},
};

const PRODUCTS = {
	PLANS: {
		HOME: lazy(() => import('../pages/products/plans/Plans')),
		SINGLE: lazy(() => import('../pages/products/plans/SinglePlan')),
		EDIT: lazy(() => import('../pages/products/plans/EditPlan')),
	},
	PRODUCTS: {
		HOME: lazy(() => import('../pages/products/products/Products')),
		SINGLE: lazy(() => import('../pages/products/products/SingleProduct')),
		EDIT: lazy(() => import('../pages/products/products/EditProduct')),
	},
};

const INSTITUTIONS = {
	COMPANIES: {
		HOME: lazy(() => import('../pages/institutions/company/Company')),
		SINGLE: lazy(() => import('../pages/institutions/company/SingleCompany')),
	},
	PROJECTS: {
		HOME: lazy(() => import('../pages/institutions/project/Project')),
		SINGLE: lazy(() => import('../pages/institutions/project/SingleProject')),
	},
	API_CALL: {
		HOME: lazy(() => import('../pages/institutions/api/ApiCall')),
		SINGLE: lazy(() => import('../pages/institutions/api/SingleApiCall')),
	},
	PROFILES: {
		HOME: lazy(() => import('../pages/institutions/profile/Profile')),
		SINGLE: lazy(() => import('../pages/institutions/profile/SingleProfile')),
	},
	WALLET: {
		HOME: lazy(() => import('../pages/institutions/wallet/Wallet')),
		SINGLE: lazy(() => import('../pages/institutions/wallet/SingleWallet')),
	},
};

const ADMINISTRATIONS = {
	ROLES: lazy(() => import('../pages/management/role/Role')),
	EDIT_ROLE: lazy(() => import('../pages/management/role/EditRole')),
	STAFF: lazy(() => import('../pages/management/staff/Administrator')),
	PROFILE: lazy(() => import('../pages/profile/Profile')),
};

const presentation = [
	/**
	 * AUTH
	 */
	{
		path: authMenu.login.path,
		element: <AUTH.LOGIN />,
	},
	{
		path: authMenu.confirmLogin.path,
		element: <AUTH.CONFIRM_LOGIN />,
	},
	{
		path: authMenu.forgetGroup.subMenu.forgetPassword.path,
		element: <AUTH.FORGET_PASSWORD />,
	},
	{
		path: authMenu.forgetGroup.subMenu.confirmation.path,
		element: <AUTH.CONFIRM_RESET />,
	},
	{
		path: authMenu.forgetGroup.subMenu.resetPassword.path,
		element: <AUTH.CONFIRM_RESET />,
	},
	{
		path: authMenu.joinTeam.path,
		element: <AUTH.JOIN_TEAM />,
	},
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: dashboardMenu.systemApiKeys.path,
		element: <LANDING.SYSTEM_API_KEYS />,
		exact: true,
	},
	{
		path: `${dashboardMenu.systemApiKeys.path}/:id`,
		element: <LANDING.EDIT_SYSTEM_API_KEYS />,
		exact: true,
	},
	{
		path: dashboardMenu.users.path,
		element: <LANDING.USERS />,
		exact: true,
	},
	{
		path: `${dashboardMenu.users.path}/:id`,
		element: <LANDING.SINGLE_USER />,
		exact: true,
	},
	{
		path: dashboardMenu.transactions.path,
		element: <LANDING.TRANSACTIONS />,
		exact: true,
	},
	{
		path: `${dashboardMenu.transactions.path}/:id`,
		element: <LANDING.SINGLE_TRANSACTION />,
		exact: true,
	},
	{
		path: dashboardMenu.currencies.path,
		element: <LANDING.CURRENCY.HOME />,
		exact: true,
	},
	{
		path: `${dashboardMenu.currencies.path}/:id`,
		element: <LANDING.CURRENCY.SINGLE />,
		exact: true,
	},
	{
		path: `${dashboardMenu.currencies.path}/edit/:id`,
		element: <LANDING.CURRENCY.EDIT />,
		exact: true,
	},
	/**
	 * Products
	 */
	{
		path: productsMenu.plans.path,
		element: <PRODUCTS.PLANS.HOME />,
		exact: true,
	},
	{
		path: `${productsMenu.plans.path}/:id`,
		element: <PRODUCTS.PLANS.SINGLE />,
		exact: true,
	},
	{
		path: `${productsMenu.plans.path}/edit/:id`,
		element: <PRODUCTS.PLANS.EDIT />,
		exact: true,
	},
	{
		path: productsMenu.products.path,
		element: <PRODUCTS.PRODUCTS.HOME />,
		exact: true,
	},
	{
		path: `${productsMenu.products.path}/:id`,
		element: <PRODUCTS.PRODUCTS.SINGLE />,
		exact: true,
	},
	{
		path: `${productsMenu.products.path}/edit/:id`,
		element: <PRODUCTS.PRODUCTS.EDIT />,
		exact: true,
	},
	/**
	 * Institutions
	 */
	{
		path: institutions.companies.path,
		element: <INSTITUTIONS.COMPANIES.HOME />,
		exact: true,
	},
	{
		path: `${institutions.companies.path}/:id`,
		element: <INSTITUTIONS.COMPANIES.SINGLE />,
		exact: true,
	},
	{
		path: institutions.projects.path,
		element: <INSTITUTIONS.PROJECTS.HOME />,
		exact: true,
	},
	{
		path: `${institutions.projects.path}/:id`,
		element: <INSTITUTIONS.PROJECTS.SINGLE />,
		exact: true,
	},
	{
		path: institutions.apiCall.path,
		element: <INSTITUTIONS.API_CALL.HOME />,
		exact: true,
	},
	{
		path: `${institutions.apiCall.path}/:id`,
		element: <INSTITUTIONS.API_CALL.SINGLE />,
		exact: true,
	},
	{
		path: institutions.profiles.path,
		element: <INSTITUTIONS.PROFILES.HOME />,
		exact: true,
	},
	{
		path: `${institutions.profiles.path}/:id`,
		element: <INSTITUTIONS.PROFILES.SINGLE />,
		exact: true,
	},
	{
		path: institutions.wallets.path,
		element: <INSTITUTIONS.WALLET.HOME />,
		exact: true,
	},
	{
		path: `${institutions.wallets.path}/:id`,
		element: <INSTITUTIONS.WALLET.SINGLE />,
		exact: true,
	},
	/**
	 * Administration
	 */
	{
		path: administrations.roles.path,
		element: <ADMINISTRATIONS.ROLES />,
		exact: true,
	},
	{
		path: `${administrations.roles.path}/edit/:id`,
		element: <ADMINISTRATIONS.EDIT_ROLE />,
		exact: true,
	},
	{
		path: administrations.staff.path,
		element: <ADMINISTRATIONS.STAFF />,
		exact: true,
	},
	{
		path: `/profile`,
		element: <ADMINISTRATIONS.PROFILE />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
