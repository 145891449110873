import React, { FC, useContext, useLayoutEffect, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useTour } from '@reactour/tour';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import { HeaderRight } from '../../../layout/Header/Header';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import { capitalizeFirstLetter } from '../../../helpers/number_utils';
import Badge from '../../../components/bootstrap/Badge';
import { Account } from '../../../helpers/GlobalInterfaces';
import AuthService from '../../../helpers/AuthService';
import { TColor } from '../../../type/color-type';

interface CommonHeaderRightProps {
	beforeChildren?: any;
	afterChildren: any;
	userData: Account;
}

const CommonHeaderRight: FC<CommonHeaderRightProps> = ({
	beforeChildren,
	afterChildren,
	userData,
}) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const { i18n } = useTranslation();

	const colorArr: TColor[] = ['info', 'primary', 'success', 'info', 'secondary', 'warning'];

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	const { setIsOpen } = useTour();

	return (
		<HeaderRight>
			{userData && userData.id && userData.id > 0 && (
				<>
					<div className='row g-3'>
						{beforeChildren && beforeChildren}
						{/* Tour Modal */}
						{localStorage.getItem('tourModalStarted') === 'shown' && (
							<div className='col-auto position-relative'>
								<Popovers trigger='hover' desc='Start the "Admin" tour'>
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										icon='Tour'
										onClick={() => setIsOpen(true)}
										aria-label='Start tour'
									/>
								</Popovers>
								<Icon
									icon='Circle'
									className={classNames(
										'position-absolute start-65',
										'text-danger',
										'animate__animated animate__heartBeat animate__infinite animate__slower',
									)}
								/>
							</div>
						)}

						{/* Dark Mode */}
						<div className='col-auto'>
							<Popovers trigger='hover' desc='Dark / Light mode'>
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									onClick={() => setDarkModeStatus(!darkModeStatus)}
									className='btn-only-icon'
									data-tour='dark-mode'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='btn-icon'
									/>
								</Button>
							</Popovers>
						</div>

						{/*	Full Screen */}
						<div className='col-auto'>
							<Popovers trigger='hover' desc='Fullscreen'>
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
									onClick={() => setFullScreenStatus(!fullScreenStatus)}
									aria-label='Toggle dark mode'
								/>
							</Popovers>
						</div>

						{/*	Notifications */}
						<div className='col-auto'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon='Notifications'
								onClick={() => setOffcanvasStatus(true)}
								aria-label='Notifications'
							/>
						</div>

						{afterChildren}
					</div>

					<OffCanvas
						id='notificationCanvas'
						titleId='offcanvasExampleLabel'
						placement='end'
						isOpen={offcanvasStatus}
						setOpen={setOffcanvasStatus}>
						<OffCanvasHeader setOpen={setOffcanvasStatus}>
							<OffCanvasTitle id='offcanvasExampleLabel'>
								Notifications
							</OffCanvasTitle>
						</OffCanvasHeader>
						<OffCanvasBody>
							{userData.unread_notifications &&
								userData.unread_notifications.map((item: any, i: number) => {
									return (
										<Alert
											// eslint-disable-next-line react/no-array-index-key
											key={i}
											icon={capitalizeFirstLetter(item.data.icon)}
											isLight
											color={
												colorArr[
													Math.floor(Math.random() * colorArr.length)
												]
											}
											className='flex-nowrap shadow-3d-primary'
											borderWidth={0}>
											<AlertHeading tag='h2' className='h4'>
												{item.data.title}
											</AlertHeading>
											<span>
												{item.data.body.substr(0, 55)}......{' '}
												<Link
													to='/'
													className={classNames(
														'text-decoration-underline  fw-bold display-10',
														{
															'text-dark': !darkModeStatus,
															'text-light': darkModeStatus,
														},
													)}>
													Read more
												</Link>
											</span>
										</Alert>
									);
								})}
						</OffCanvasBody>
					</OffCanvas>
				</>
			)}
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
