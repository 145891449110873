export const links = {
	// bearerUrl: 'https://meverify.test/api/v2',
	// galleryUrl: 'https://meverify.test/v2/assets',
	bearerUrl: 'https://api.meverify.com.ng/api/v2',
	galleryUrl: 'https://api.meverify.com.ng/v2/assets',
	paymentRedirectUrl: 'http://localhost:3000',
	documentationUrl: '',
	pages: {
		// Auth URLs
		login: 'login',
		loginVerify: 'verify-login',
		createAccount: 'create-account',
		createToken: 'create-verification-token',
		checkToken: 'check-verification-token',
		verifyToken: 'verify-verification-token',
		forgotPassword: 'forgot-password',
		resetPassword: 'reset-password',
		logout: 'account/logout',
		// profile
		profile: 'account/profile',
		changePass: 'account/change-password',
		updateProfile: 'account/update-profile',
		toggleTwofactor: 'account/toggle-2-factor',
		// staff invitations
		listInvites: 'invites/invitations',
		createInvites: 'invites/create-invite',
		deleteInvites: 'invites/delete-invite',
		// admin routes
		listAdmin: '',
		listSimpleAdmin: 'simple',
		viewAdmin: 'view-admin',
		editAdmin: 'edit-admin',
		changeAdminStatus: 'change-status',
		deleteAdmin: 'delete-admin',
		dashboard: 'dashboard',
		// api calls
		listApiCalls: 'api-calls',
		deleteApiCalls: 'api-calls/delete-api-call',
		// companies
		listCompany: 'companies',
		chnageCompanyStatus: 'companies/change-status',
		deleteCompany: 'companies/delete-company',
		// Plan routes
		listPlans: 'plans',
		listSimplePlans: 'plans/simple',
		createPlan: 'plans/create-plans',
		editPlan: 'plans/edit-plan',
		deletePlan: 'plans/delete-plan',
		// Currency routes
		listCurrencies: 'currencies',
		listSimpleCurrencies: 'currencies/simple',
		createCurrency: 'currencies/create-currency',
		editCurrency: 'currencies/edit-currency',
		deleteCurrency: 'currencies/delete-currency',
		// Product Routes
		listProducts: 'products',
		createProduct: 'products/create-products',
		editProducts: 'products/edit-product',
		deleteProducts: 'products/delete-product',
		// Profile Routes
		listProfiles: 'profiles',
		deleteProfile: 'profiles/delete-profile',
		// Project Routes
		listProjects: 'projects',
		changeProjectStatus: 'projects/change-status',
		deleteProject: 'projects/delete-project',
		// Role Routes
		listRoles: 'roles',
		listSimpleRoles: 'roles/simple',
		createRole: 'roles/create-role',
		editRole: 'roles/edit-role',
		deleteRole: 'roles/delete-role',
		// Permission Routes
		listPermissions: 'permissions',
		// System API Key Routes
		listSystemApiKeys: 'system-api-keys',
		createSystemApiKey: 'system-api-keys/create-key',
		editSystemApiKey: 'system-api-keys/edit-key',
		deleteSystemApiKey: 'system-api-keys/delete-key',
		// Transaction Routes
		transactions: 'transactions',
		deleteTransaction: 'transactions/delete-transaction',
		// User Routes
		listUsers: 'users',
		changeUserStatus: 'users/change-status',
		deleteUser: 'users/delete-user',
		// Wallet Routes
		listWallets: 'wallets',
		creditWallet: 'wallets/credit',
		debitWallet: 'wallets/debit',
	},
};

export function getLink(link: string) {
	return `${links.bearerUrl}/admin/${link}`;
}
