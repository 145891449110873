import React from 'react';
import { RouteProps } from 'react-router-dom';
import { authMenu } from '../menu';
import DefaultFooter from '../pages/_layout/_footers/DefaultFooter';
import AuthService from '../helpers/AuthService';

const isUserLoggedIn = AuthService().isUserLogged();

const footers: RouteProps[] = [
	{ path: authMenu.login.path, element: null },
	{ path: authMenu.confirmLogin.path, element: null },
	{ path: authMenu.forgetGroup.subMenu.forgetPassword.path, element: null },
	{ path: authMenu.forgetGroup.subMenu.confirmation.path, element: null },
	{ path: authMenu.forgetGroup.subMenu.resetPassword.path, element: null },
	{ path: `${authMenu.joinTeam.path}/:slug`, element: null },
	{ path: '*', element: isUserLoggedIn ? <DefaultFooter /> : null },
];

export default footers;
